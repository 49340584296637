import React, { Component } from 'react'

export class Ticker extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentCount: 128676 // Accurate as of 2019-07-27
    }
    this.timer = this.timer.bind(this)
  }

  componentDidMount () {
    let intervalId = setInterval(this.timer, 2000)
    this.setState({intervalId: intervalId})
  }

  componentWillUnmount () {
    clearInterval(this.state.intervalId)
  }

  timer () {
    const toAdd = (3 - (Math.floor(Math.random() * 4) + 1))
    const newCount = this.state.currentCount + toAdd
    this.setState({currentCount: newCount})
  }

  render () {
    return (
      <span>
        {this.state.currentCount.toLocaleString()}
      </span>
    )
  }
}

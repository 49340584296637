import React, { Component } from 'react'

import Layout from '../components/layout'
import Particles from 'react-particles-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Ticker } from '../components/ticker'
import { Link } from '@reach/router'

class IndexPage extends Component {

  constructor (props) {
    super(props)

    this.state = {particles: ''}
  }

  componentDidMount () {
    if (window.innerWidth > 600) {
      const particles = (
        <div className="absolute w-full align-top z-10 opacity-50 overflow-hidden">
          <div className="min-w-full" style={{width: '2000px'}}>
            <Particles
              params={{
                'particles': {
                  'number': {
                    'value': 100
                  },
                  'size': {
                    'value': 3
                  }
                },
                'interactivity': {
                  'events': {
                    'onhover': {
                      'enable': true,
                      'mode': 'repulse'
                    }
                  }
                }
              }}/>
          </div>
        </div>
      )
      this.setState({particles: particles})
    } else {
      this.setState({particles: ''})
    }
  }

  render () {
    return (
      <Layout>
        <div className="bg-black text-white leading-loose relative">
          {this.state.particles}
          <div className="container mx-auto relative z-30">
            <div className="md:flex">
              <div className="md:w-1/2 p-4 md:py-32">
                <h1 className="font-light text-4xl pb-2">
                  Real-time website monitoring
                </h1>

                <p>
                  Whoops alerts you when something isn't working right, and monitors your sites' performance over time
                </p>

                <p className="mt-10 pb-10">
                  <a href="https://app.whoops.io"
                     className="rounded-full border border-solid border-grey-darker p-4 text-white font-bold no-underline hover:bg-grey-darker">
                    Sign up now
                  </a>
                </p>
              </div>

              <div className="md:w-1/2 md:text-right p-4 md:py-32">
                <p className="text-grey-dark">
                  Website / server checks in the past 24 hours:
                </p>
                <span className="text-5xl">
              <Ticker/>
            </span>

              </div>
            </div>
          </div>

          <div className="text-center px-4 pt-10 lg:pt-0">
            <div className="lg:max-w-3xl mx-auto xl:-mt-16">
              <img
                className="hidden md:inline max-w-full border-4 rounded border-pink z-30 relative"
                src={require('../images/whoops-screenshot.png')}
                alt="Whoops.io Uptime Monitoring Dashboard"/>
              <img
                className="md:hidden max-w-full border-4 rounded border-pink z-30 relative"
                src={require('../images/whoops-screenshot-iphonex-2.png')}
                alt="Whoops.io Uptime Monitoring Dashboard"/>

              <div className="shadow-lg -mt-12 relative z-40"
                   style={{backgroundImage: 'linear-gradient(to top, rgba(0,0,0,0.1), rgba(0,0,0,0)'}}>&nbsp;</div>
            </div>
          </div>
        </div>


        {/* Features */}
        <div className="px-4 lg:px-12 py-10 bg-grey-lighter leading-loose relative z-40">

          <div className="mt-16">
            <div className="md:flex">
              <div className="md:w-1/3">
                <div className="m-4 p-10 shadow bg-white rounded-lg">
                  <FontAwesomeIcon icon="heartbeat" className="float-right text-pink" fixedWidth size="1x"/>
                  <h4>
                    Uptime Monitoring
                  </h4>
                  <p className="text-grey-darkest">
                    Monitor that your website or server is online and available
                  </p>
                </div>
              </div>
              <div className="md:w-1/3">
                <div className="m-4 p-10 shadow bg-white rounded-lg">
                  <FontAwesomeIcon icon="clock" className="float-right text-pink" fixedWidth size="1x"/>
                  <h4>
                    Real-time Monitors
                  </h4>
                  <p className="text-grey-darkest">
                    Check your website or server as often as <em>every second</em>.
                  </p>
                </div>
              </div>
              <div className="md:w-1/3">
                <div className="m-4 p-10 shadow bg-white rounded-lg">
                  <FontAwesomeIcon icon="bell" className="float-right text-pink" fixedWidth size="1x"/>
                  <h4>
                    Instant Alerts
                  </h4>
                  <p className="text-grey-darkest">
                    When something goes down, we'll send you an alert instantly.
                  </p>
                  <p className="text-grey-darkest">
                    Find out when something goes wrong before your customers do
                  </p>
                </div>
              </div>
            </div>
            <div className="md:flex">
              <div className="md:w-1/3">
                <div className="m-4 p-10 shadow bg-white rounded-lg">
                  <FontAwesomeIcon icon="users" className="float-right text-pink" fixedWidth size="1x"/>
                  <h4>
                    Teams and Organisations
                  </h4>
                  <p className="text-grey-darkest">
                    Create a workspace and share your monitors with your team
                  </p>
                </div>
              </div>
              <div className="md:w-1/3">
                <div className="m-4 p-10 shadow bg-white rounded-lg">
                  <FontAwesomeIcon icon="chart-bar" className="float-right text-pink" fixedWidth size="1x"/>
                  <h4>
                    Statistics
                  </h4>
                  <p className="text-grey-darkest">
                    Human-friendly real-time dashboards identify trends and insights from your checks.</p>
                  <p className="text-grey-darkest">Measure your actual uptime against SLAs</p>
                </div>
              </div>
              <div className="md:w-1/3">
                <div className="m-4 p-10 shadow bg-white rounded-lg">
                  <FontAwesomeIcon icon="code" className="float-right text-pink" fixedWidth size="1x"/>
                  <h4>
                    REST API
                  </h4>
                  <p className="text-grey-darkest">
                    Get full access to your data through our REST API
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-black py-16 leading-loose relative z-50">

          <div className="lg:flex w-full">
            <div className="flex-col lg:w-1/3">
              <div className="shadow shadow-lg bg-black rounded mx-4 text-white mx-8 border-t border-grey-darkest mb-8">

                <a href="https://app.whoops.io/signup"
                   className="rounded p-4 text-white no-underline font-bold bg-black hover:bg-grey-darker bg-grey-darkest float-right font-medium m-4">Sign
                  up now</a>

                <div className="text-3xl px-4 pt-4">
                  Free Plan
                </div>
                <div className="px-4 text-lg font-bold text-grey-dark -mt-2 mb-4">
                  Free forever
                </div>

                <ul className="list-reset px-4 pb-4 text-sm">
                  <li>20 HTTP(S) or TCP checks</li>
                  <li>60 second intervals</li>
                  <li>2 users</li>
                  <li>30 day retention</li>
                  <li>No credit/debit card details</li>
                </ul>
              </div>
            </div>
            <div className="flex-col lg:w-1/3">
              <div className="shadow shadow-lg bg-black rounded mx-4 text-white mx-8 border-t border-grey-darkest mb-8">

                <a href="https://app.whoops.io/signup"
                   className="rounded p-4 text-white no-underline font-bold bg-black hover:bg-grey-darker bg-grey-darkest float-right font-medium m-4">Sign
                  up now</a>

                <div className="text-3xl px-4 pt-4">
                  Pro Plan
                </div>
                <div className="px-4 text-lg font-bold text-grey-dark -mt-2 mb-4">
                  $5 per month <span
                  className="text-white text-normal rounded-full text-sm p-1 px-2 bg-pink text-pink-darkest shadow ml-2">Beta Price</span>
                </div>

                <ul className="list-reset px-4 pb-4 text-sm">
                  <li>500 HTTP(S) or TCP checks</li>
                  <li>10 second intervals</li>
                  <li>Unlimited users</li>
                  <li>12 month retention</li>
                </ul>
              </div>
            </div>
            <div className="flex-col lg:w-1/3">
              <div className="shadow shadow-lg bg-black rounded mx-4 text-white mx-8 border-t border-grey-darkest mb-8">

                <a href="mailto:hello@whoops.io"
                   className="rounded p-4 text-white no-underline font-bold hover:bg-grey-darker float-right font-medium m-4">Talk
                  to us</a>

                <div className="text-3xl px-4 pt-4">
                  Need more?
                </div>

                <div className="p-4 text-sm">
                  <p className="pb-2">
                    Need more than our Pro Plan includes? We can handle thousands of concurrent checks, it intervals of
                    every second.
                  </p>
                  <p>
                    Get in touch with the team and explain what you need, and we'll figure something out together.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="text-center bg-grey-lighter px-4 py-16 leading-loose border-t ">
          <div className="container mx-auto max-w-2xl">
            <h2 className="font-light text-4xl">Sign up today for <span
              className="rounded-full bg-black text-white p-1 px-2 text-2xl font-medium">Beta</span> pricing</h2>
            <p className="text-grey-darkest py-2">
              We haven't announced our full pricing yet; the prices you see today are only for users who sign up today
              during the Whoops.io public beta.
            </p>
            <p className="text-grey-darkest py-2">
              When we announce or full pricing, customers who signed up during the beta period will be able to keep
              their existing beta pricing plan.
            </p>
            <p className="py-8">
              <a href="https://app.whoops.io/signup"
                 className="text-white bg-pink rounded p-4 px-8 no-underline font-medium text-2xl shadow-lg border-b-4 border-pink-dark hover:border-pink hover:bg-pink-light">
                Sign up now
              </a>
            </p>
            <p>
              <Link className="text-grey text-sm no-underline my-2 hover:underline hover:text-grey-dark" to="/terms">See
                the terms for more information</Link>
            </p>
          </div>
        </div>

      </Layout>
    )
  }
}

export default IndexPage
